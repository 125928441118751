<script setup>

// ios에서 tab이 안바뀌는 현상이 있어서 추가
// 0 : 홈
// 1 : lecture
// 2 : forum
// 3 : contact
// 4 : mypage
const  changeTab = (index) => {
  console.log(`===> changeTab: ${index}`);
  if (window.webkit && window.webkit.messageHandlers.changeTab) {
    window.webkit.messageHandlers.changeTab.postMessage(index);
    console.log(`Native tab updated to index: ${index}`);
  } else {
    console.error("changeTab handler not found");
  }
}

// iPhone 이면 changeTab

 window.changeTab = changeTab;

</script>

<template>
    <router-view :key="$route.fullPath"></router-view>
</template>

<style scoped>

</style>