const DEV_API_URL = '/api'
const PROD_API_URL = 'https://www.candoimplant.com/api'
const LOCAL_API_URL = 'http://localhost:8222/api'
const NETWORK = process.env.VUE_APP_NETWORK || 'localhost'

// API Url : 빌드 환경보다, 동작 환경에 따라 설정하도록 변경함.
const apiUrl = NETWORK == 'localhost' ? LOCAL_API_URL :
    (NETWORK == 'development' ?  DEV_API_URL :
    (NETWORK == 'production' ? PROD_API_URL : LOCAL_API_URL))

const isProduction = process.env.NODE_ENV == 'production' ? true : false

const env = {
    isProduction,
    API_URL: apiUrl,
}

module.exports = env