import { createApp } from 'vue'
import App from './App.vue'

import router from "@/router"
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@/assets/ckeditor5/ckeditor5-content.css';
import '@/assets/ckeditor5/ckeditor5.css';
import '@/assets/css/common.css'
import '@/assets/fonts/suit/woff2/SUIT.css'
import PullToRefreshPlugin from '@/components/pullToRefresh'

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)    // pinia 플러그인 추가 (localstorage에 저장)

app.use(pinia)
app.use(router)
app.use(CKEditor)

app.use(PullToRefreshPlugin, {
    // onRefresh 콜백: 원하는 API 호출이나 로직을 넣을 수 있음.
    // 아래 예시는 1초 후 페이지를 새로고침하는 예제입니다.
    onRefresh() {
        return new Promise((resolve) => {
            console.log('Pull-to-refresh triggered');
            setTimeout(() => {
                window.location.reload();
                resolve();
            }, 1000);
        });
    },
    // 전체 페이지에 적용하려면 mainElement를 'body'로 설정
    mainElement: 'body',
    // 사용자에게 표시할 메시지 (옵션)
    refreshMessage: '새로고침 진행중...'
});

app.config.productionTip = false
app.config.errorHandler = (err, vm, info) => {
    // 에러 처리
    // `info` 는 Vue 관련 에러 정보 입니다. 예) 라이프사이클 훅에 에러를 발견
    console.error(err)
    console.error(vm)
    console.error(info)
}
// app 전역 설정 변수
app.config.globalProperties.version = '1.0.0.'

// html 공백 유지
app.config.compilerOptions.whitespace = 'preserve'

// html내, vue 템플릿 구분자 변경
//  ['${', '}'] -> ['{{', '}}']
app.config.compilerOptions.delimiters = ['{{', '}}']    // 기본값.

// html 주석 제거한다.. 기본값은 true
app.config.compilerOptions.comments = false

app.mount('#app')
