import PullToRefresh from 'pulltorefreshjs';

export default {
    install(app, options = {}) {
        // ① 사용자에게 표시할 메시지용 DIV 생성
/*        const messageEl = document.createElement('div');
        messageEl.id = 'ptr-custom-message';
        messageEl.style.position = 'fixed';
        messageEl.style.top = '20px';
        messageEl.style.left = '50%';
        messageEl.style.transform = 'translateX(-50%)';
        messageEl.style.padding = '10px 20px';
        messageEl.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
        messageEl.style.color = '#fff';
        messageEl.style.borderRadius = '5px';
        messageEl.style.zIndex = '10000';
        messageEl.style.display = 'none';
        document.body.appendChild(messageEl);*/

        // ② 사용자가 전달한 onRefresh 콜백을 wrapping
        const userOnRefresh = options.onRefresh || function () {
            // 기본 동작은 1초 후 전체 페이지 새로고침
            return new Promise((resolve) => {
                window.location.reload();
                resolve();
            });
        };

        const onRefreshWrapper = () => {
            // 메시지 표시
            // messageEl.innerText = options.refreshMessage || '새로고침 진행중...';
            // messageEl.style.display = 'block';

            // 사용자가 지정한 onRefresh 함수 실행
            const refreshResult = userOnRefresh();
            // Promise를 반환하는 경우 완료 후 메시지 숨김
            if (refreshResult && typeof refreshResult.finally === 'function') {
                refreshResult.finally(() => {
                    // messageEl.style.display = 'none';
                });
            } else {
                // Promise가 아닐 경우 바로 메시지 숨김
                // messageEl.style.display = 'none';
            }
        };

        // ③ 기본 옵션 설정 (필요시 options로 재정의 가능)
        const defaultOptions = {
            mainElement: options.mainElement || 'body', // 전체 페이지에 적용
            onRefresh: onRefreshWrapper,
            instructionsPullToRefresh: options.instructionsPullToRefresh || 'Pull down to refresh',
            instructionsReleaseToRefresh: options.instructionsReleaseToRefresh || 'Release to refresh',
            instructionsRefreshing: options.instructionsRefreshing || 'Refreshing...',
            // 화면이 최상단일 때만 pull-to-refresh 작동하도록 함
            shouldPullToRefresh() {
                return window.scrollY === 0;
            }
        };

        // ④ pull-to-refresh 초기화
        PullToRefresh.init(defaultOptions);
    }
};
